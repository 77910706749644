<script>
  import TopAppBar, { Row, Section, Title } from "@smui/top-app-bar";
</script>

<div class="header">
  <TopAppBar variant="static">
    <Row>
      <Section>
        <Title>Korean Romanizer</Title>
      </Section>
    </Row>
  </TopAppBar>
</div>

<style>
  .header {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
  }
</style>
