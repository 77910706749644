<script>
  import Textfield from "@smui/textfield";
  import HelperText from "@smui/textfield/helper-text/index";
  import LayoutGrid, { Cell } from "@smui/layout-grid";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import Button, { Label } from "@smui/button";

  import TopBar from "./TopBar.svelte";
  import Romanizer from "./Romanizer.svelte";
  import Footer from "./Footer.svelte";

  import "./smui.css";
</script>

<svelte:head>
  <title>Korean Romanizer</title>
  <html lang="en" />
</svelte:head>

<div class="container">
  <TopBar />
  <Romanizer />
  <Footer />
</div>

<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
/>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR&family=Roboto:wght@300;500;900&display=swap");

  .container {
    margin: 0 auto;
    width: 80%;
  }

  @media screen and (max-width: 480px) {
    .container {
      margin: 0 auto;
      width: 95%;
    }
  }
</style>
